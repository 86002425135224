<template>
  <div class="option-container">
    <div class="row-div" :class="{ 'hidden-arrows': !allows_crud }">
      <span class="option-letter">{{
        String.fromCharCode(96 + option.order) + ")"
      }}</span>
      <div class="text-div">
        <StyledTextArea
          v-if="user.is_professor || !is_finished"
          v-model="option.text"
          class="option-textarea"
          placeholder="Escriba la opción aquí..."
          :state="true"
          :disabled="!allows_crud"
        ></StyledTextArea>
        <template v-else>
          <p class="option-text">{{ option.text }}</p>
        </template>
      </div>
      <StyledInputNumber
        :id="`option-score-input-${option.id}`"
        :key="option_score_key"
        v-model="option.score"
        :max="question.how_many_selected == 1 ? this.question.score : null"
        :title="alert_message"
        v-if="!can_answer || user.is_professor"
        class="option-score-input noprint"
        :class="{
          'option-score-alert': option_alert || question_score_error,
          'option-score-alert-single': option_score_error,
        }"
        v-b-tooltip.v-secondary.top.noninteractive="'Puntaje de Opción'"
        @input="debounceSave"
        :disabled="!allows_crud"
      ></StyledInputNumber>
      <!-- <div
        v-if="allows_crud"
        class="row-div mx-1"
        v-b-tooltip.bottom.noninteractive
        title="Retroalimentación"
      >
        <b-form-checkbox v-model="show_feedback" switch
          >Retroalimentar</b-form-checkbox
        >
      </div> -->
      <div class="image-div col-div">
        <button
          class="btn-delete-option"
          v-if="allows_crud"
          v-b-tooltip.bottom.noninteractive.v-secondary
          title="Eliminar"
          @click="deleteOption"
        >
          <b-icon-trash></b-icon-trash>
        </button>
      </div>
    </div>
    <b-tooltip
      v-if="option_score_error"
      :target="`option-score-input-${option.id}`"
      :show="option_score_error"
      placement="right"
      variant="secondary"
      delay="500"
    >
      {{ alert_message }}
    </b-tooltip>
    <span v-if="option_alert" class="text-danger ml-2">
      <b-icon icon="exclamation-circle" class="mr-1"></b-icon> Debe ingresar un
      puntaje válido para la opción.
    </span>
    <div class="row-div">
      <template v-if="show_feedback && allows_crud">
        <div class="feedback-div">
          <StyledTextArea
            v-model="option.comment"
            placeholder="Escriba la retroalimentación aquí..."
            :state="true"
            :disabled="!allows_crud"
          ></StyledTextArea>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "OptionRow",
  mixins: [validationMixin],
  components: {
    StyledTextArea: () => import("@/components/reusable/StyledTextArea"),
    StyledInputNumber: () => import("@/components/reusable/StyledInputNumber"),
  },
  props: {
    question_id: {
      type: Number,
      required: true,
    },
    Option: {
      type: Object,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    can_answer: {
      type: Boolean,
      default: false,
    },
    is_finished: {
      type: Boolean,
      default: false,
    },
    question_score_error: {
      type: Boolean,
      default: false,
    },
    how_many_selected: {
      type: Number,
    },
  },
  data() {
    return {
      option: {
        id: this.Option ? this.Option.id : generateUniqueId(),
        text: this.Option ? this.Option.text : "",
        order: this.Option ? this.Option.order : 1,
        score: this.Option ? this.Option.score : 1,
        question: this.question_id,
        comment: this.Option ? this.Option.comment : "",
      },
      saving: false,
      show_feedback: false,
      option_alert: false,
      count_option_changes: 0,
      option_score_error: false,
      alert_message: "",
      option_score_key: 0,
    };
  },
  validations() {
    return {
      option: {
        score: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      questionOptions: names.MULTIPLE_CHOICE_QUESTION_OPTIONS,
      questions: names.QUESTIONS,
      user: "getUser",
    }),
    question() {
      return this.questions.find((x) => x.id == this.question_id);
    },
    // storedOption() {
    //   return this.questionOptions.find((x) => x.id == this.option.id);
    // },
    // selected() {
    //   if (!this.storedOption || !this.question) return false;
    //   return this.question.selected == this.storedOption.id ? true : false;
    // },
  },
  methods: {
    // slotSelectionChanged(checked) {
    //   this.$emit("selection", this.option.id, checked);
    // },
    validateState(key) {
      const { $dirty, $error } = this.$v.option[key];
      return $dirty ? !$error : null;
    },
    debounceSave() {
      if (this.saving) return;
      this.saving = true;
      setTimeout(() => {
        this.save();
      }, 2600);
    },
    save() {
      if (isNaN(this.option.id)) {
        this.createOption();
      } else {
        this.updateOption();
      }
    },
    createOption() {
      this.$store
        .dispatch(names.POST_MULTIPLE_CHOICE_QUESTION_OPTION, this.option)
        .then(() => {
          if (isNaN(this.Option.id)) this.$emit("delete", this.Option.id);
          toast("Opción creada.");
          this.saving = false;
        });
    },
    updateOption() {
      this.option_alert = false;
      this.$v.option.$touch();
      if (
        this.$v.option.$anyError ||
        (this.how_many_selected == 1 && this.option.score > this.question.score)
      ) {
        toast(
          "No se pudieron guardar los cambios. Hay errores que debe corregir."
        );
        this.saving = false;
        this.option_alert = true;
        return;
      } else {
        let temp_option_score = this.Option.score;

        temp_option_score = parseInt(this.option.score) - temp_option_score;
        this.$store
          .dispatch(names.UPDATE_MULTIPLE_CHOICE_QUESTION_OPTION, this.option)
          .then(() => {
            // this.options_total_score += this.option.score;
            toast("Opción actualizada.");
            this.count_option_changes += 1;
            if (this.how_many_selected == 1) {
              this.$emit(
                "optionSingleScoreChanged",
                parseInt(this.option.score)
              );
            } else {
              this.$emit("optionScoreChanged", temp_option_score);
            }
            this.saving = false;
          });
      }
    },
    deleteOption() {
      if (isNaN(this.Option.id)) {
        this.$emit("delete", this.Option.id);
        return;
      }
      this.$swal({
        title: "¿Está seguro de que desea eliminar la opción?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$emit("deleted", this.option);
          this.$store
            .dispatch(
              names.DELETE_MULTIPLE_CHOICE_QUESTION_OPTION,
              this.option.id
            )
            .then(() => {
              toast("Opción eliminada.");
            });
        }
      });
    },
    patchOption(item) {
      let payload = {
        option_id: this.option.id,
        item: item,
      };
      this.$store
        .dispatch(names.PATCH_MULTIPLE_CHOICE_QUESTION_OPTION, payload)
        .then(() => {
          this.saving = false;
          toast("Opción actualizada.");
        });
    },
  },
  // mounted() {
  //   this.$store.dispatch(
  //     names.FETCH_MULTIPLE_CHOICE_QUESTION_OPTIONS,
  //     this.question_id
  //   );
  // },
  created() {
    this.$emit("getOptionScore", this.option.score);
  },
  mounted() {},
  watch: {
    "option.text"() {
      this.debounceSave();
    },
    "option.comment"() {
      this.debounceSave();
    },
    "option.score"() {
      this.option_score_error = false;
      this.debounceSave();
      if (
        this.how_many_selected == 1 &&
        this.option.score > this.question.score
      ) {
        this.option_score_key += 1;
        this.option_score_error = true;
        this.alert_message = `El puntaje de opción no debe superar el de la pregunta (${this.question.score}).`;
      }
    },
  },
};
</script>

<style scoped>
.option-container {
  display: flex;
  flex-direction: column;
}
.option-textarea {
  width: 98%;
}
.text-div {
  width: 100%;
  margin: 0.1em;
}
.image-div {
  margin: 0.1em;
}
.close-div {
  margin: 0.1em;
}
.row-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.col-div {
  display: flex;
  flex-direction: column;
}
.feedback-div {
  margin: 0px 0.5em;
  padding: 0px 0.5em;
  width: 100%;
}
.option-score-input {
  width: 7%;
  margin: 0.1em;
  margin-right: 1em;
  margin-bottom: 0.7em;
  top: 0;
}
.option-score-alert {
  border: 1px solid #dc3545 !important;
}
.option-score-alert-single {
  border: 1px solid #dc3545 !important;
}
.option-single-score-error {
  color: #dc3545;
  opacity: 1;
  margin-right: 0;
  margin-left: auto;
}
.blinking {
  animation: opacity 0.2s ease-in-out;
  opacity: 1;
}
.btn-delete-option {
  background: transparent;
  border: none;
  margin-bottom: 0.5rem;
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hidden-arrows >>> input[type="number"]::-webkit-inner-spin-button,
.hidden-arrows >>> input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.option-letter {
  margin-bottom: 0.5em;
}
@media (max-width: 1200px) {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>